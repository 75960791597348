import {
  addZeroToDate,
  buildId,
  handleViolations,
  POST
} from "../common";
import DocumentUploader from "./../documents/document-uploader";

/**
 *
 */
export default class ProfileUpdater {

  _conflictManager;

  get conflictManager() {
    return this._conflictManager;
  }

  set conflictManager(value) {
    this._conflictManager = value;
    value.save = this.save;
  }

  $form;

  id;

  get buttons() {
    return this.$form.find('input[type="submit"],input[type="button"],button');
  }

  violationsMapping;

  onRedirect = (p) => {
    if (this._conflictManager) {
      this._conflictManager.onRedirect(p);
    }
  };


  /**
     *
     * @param id
     */
  constructor(id) {

    this.$form = $('#' + id);
    if (this.$form.length === 0) {
      this.$form = $('form[name="' + id + '"]');
    }
  }


  init() {


    this.$loader = this.$form.find('.loader');
    this.name = this.$form.attr('name');

    this.registerEvents();

  }

  registerEvents() {

    this.$form.on('submit', (e) => {
      this.onSubmit(e);
    });

  }


  async onSubmit(e) {

    e.preventDefault();

    this.$loader.show();

    this.buttons.prop('disabled', true);

    this.$form.find('.with-error').removeClass('with-error');
    this.$form.find('.form-error').remove();

    let p = await this.getFormData();

    try {
      let d = await this.save(p);

      this.$loader.hide();

      this.buttons.prop('disabled', false);

      this.onRedirect(d);
    } catch (e) {
      this.onError(p,e);
    }
  }

  // abstract
  async getFormData() {
  }


  onError(data,e) {

    handleViolations(e, this.name, this.$form, this.violationsMapping);

    this.buttons.prop('disabled', false);

    console.error(e);
  }

  // abstract
  // eslint-disable-next-line no-unused-vars
  async save(data) {}


  /**
     *
     * @param p
     * @param field string
     * @param fieldId string|undefined
     * @return {Promise<*>}
     */
  async handleUpload(p, field, fieldId) {

    let $field;
    if (fieldId) {
      $field = this.$form.find('#' + fieldId).get(0);
    } else {
      $field = this.$form.find('input[type="file"]').get(0);
    }
    if ($field) {
      let files = $field.files;

      if (files && files[0]) {
        let file = await DocumentUploader.upload(files[0]);
        p[field] = file.key;
      }
    } else {
      delete p[field];
    }


    if (!p[field] || p[field] instanceof FileList) {
      delete p[field];
    }

    return p;

  }


  /**
     *
     * @param p
     * @return {*}
     */
  handleDateOfBirth(p) {


    // eslint-disable-next-line no-prototype-builtins
    if(!p.user.hasOwnProperty("dateOfBirth")) {
      return p;
    }

    if (p.user.dateOfBirth) {
      p.user.dateOfBirth = addZeroToDate(p.user.dateOfBirth.year, p.user.dateOfBirth.month, p.user.dateOfBirth.day);
    } else {
      p.user.dateOfBirth = null;
    }

    return p;
  }


  /**
     *
     * @param data
     * @param elem
     * @return {Promise<*>}
     */
  async handleCanvas(data, elem, field) {


    if (!field) {
      field = "signature";
    }

    let pad = $(elem).data('pad');

    if (!pad || !pad.blured) {
      delete data[field];
      return data;
    }

    let url = pad.toDataURL();

    let d = await POST(Routing.generate('api_upload_file_base_64'), {
      file: url,
      name: field
    });

    data[field] = d.key;

    return data;

  }

  /**
     *
     * @param p
     */
  handlePhones(p) {

    if (p.user.phones) {
      let phones = [];
      Object.keys(p.user.phones).forEach((key) => {
        let phone = p.user.phones[key];

        if (phone.number) {
          phone = this.handleId(phone, "phones");
          phones.push(phone);
        }
      });

      p.user.phones = phones;

    }

    return p;

  }

  handleAddresses(p) {

    if(p.user.address) {
      p.addresses = [p.user.address];
      delete p.user.address;
    }

    if(p.addresses && !Array.isArray(p.addresses)) {
      p.addresses = [p.addresses];
    }

    ["addresses","laboratories","pharmacies"].forEach((key) => {

      if(!p[key] || p[key].length === 0) {
        return;
      }

      p[key] = p[key].filter((address) => !!address);

      p[key] = p[key].map((address) => {
        return this.handleAddress(address);
      }).filter((address) => !!address);
    });

    return p;

  }


  /**
     *
     * @param address
     * @return {{id}|*}
     */
  handleAddress(address) {

    if(typeof address === "string") {
      return address;
    }

    address = this.handleId(address, "addresses");

    if (!address.address) {
      return null;
    }

    delete address.autocomplete;

    ['latitude', 'longitude'].forEach((field) => {
      if (!address[field]) {
        delete address[field];
      }
    });

    if(address.phone) {
      address.phone = {
        "number" : address.phone
      };
    } else {
      delete address.phone;
    }

    if(address.type) {
      if(typeof address.type === "object" && address.type['@id']) {
        address.type = address.type['@id'];
      }

    } else {
      delete address.type;
    }


    return address;

  }


  /**
     *
     * @param data
     * @param prefix
     * @return {{id}|*}
     */
  handleId(data, prefix) {

    if (data.id) {
      data.id = buildId(prefix, data.id);
    } else {
      delete data.id;
    }

    return data;

  }


  /**
     *
     * @param data
     * @param field
     * @return {*}
     */
  handleInt(data, field) {
    if (isNaN(data[field])) {
      data[field] = null;
    }

    if (data[field]) {
      data[field] = (data[field]).toString();

    }

    return data;

  }

  /**
     * Format specialties id to IRIs
     * @param data
     * @returns {*[]}
     */
  handleSpecialties(data) {
    return data.map(elem => buildId('specialties', elem.id));
  }
}
